<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_19914_316405)">
      <path
        d="M1 9.97969C1 9.31969 1.08 8.64969 1.23 7.97969C2.35 3.13969 7.18 0.119695 12.02 1.22969C13.94 1.66969 15.58 2.69969 16.78 4.07969"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M13.6016 4.86969L18.7616 7.90969L18.8116 1.92969L13.6016 4.86969Z"
        fill="currentColor"
      />
      <path
        d="M18.9987 10.0195C18.9987 10.6795 18.9188 11.3495 18.7688 12.0195C17.6488 16.8595 12.8188 19.8795 7.97875 18.7695C6.05875 18.3295 4.41875 17.2995 3.21875 15.9195"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M6.3975 15.1298L1.2375 12.0898L1.1875 18.0698L6.3975 15.1298Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_19914_316405">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
